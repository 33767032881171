import { brands } from 'reaxl-brand';

// Get pg ids by page
// This should replace dataIsland.BIRF.page.pg
export const pageIdsByPageName = {
    sfp: () => ({ pg: 'fyc_sf' }),
    fordlanding: () => ({ pg: 'fyc_lnd_fba' }),
    bap: () => ({ pg: 'bld_and_pr' }),
    vdp: ({ brand, id, query, listingType = '' }) => {
        let pg = 'fyc_vdp';
        let brandPg;

        if (!id) {
            const { zip } = query;
            const make = query.makeCodeList;
            const model = query.modelCodeList;

            pg = 'fyc_exvdp_nmmz';

            if (zip && make && model) {
                pg = 'fyc_exvdp_mmz';
            } else if (!zip && make && model) {
                pg = 'fyc_exvdp_make_mdl';
            }
        }

        if (brand === brands.KBB_BRAND) {
            if (id) {
                brandPg = listingType.toUpperCase() === 'NEW' ? 'classdetailnew' : 'classdetailused';
            } else {
                brandPg = 'classdetailexpired';
            }
        }

        return { pg, brandPg };
    },
    srp: ({ brand, showNoResults, listingTypes = [], listingType = [] }) => {
        let pg = 'fyc_srl';
        let brandPg;
        if (showNoResults) {
            pg = 'fyc_ncf';
        }

        let condition = listingType.length > 0 ? listingType : listingTypes;

        if (brand === brands.KBB_BRAND) {
            condition = [].concat(condition);
            condition = condition.map((value) => value.toLowerCase());
            const usedListingType = condition.length > 0 && (condition.includes('used') || condition.includes('certified')) && !condition.includes('new');
            brandPg = usedListingType ? 'classlistused' : 'classlistnew';
        }

        return { pg, brandPg };
    },
};
