import _get from 'lodash/get';

import {
    compareListingsClick,
    compareListingsModalPageView,
    headerGeneralEventHandler,
    offsiteBrowserPushNotificationClick,
    offsiteBrowserPushNotificationImpression,
    snap360Click,
} from 'reaxl-analytics-handlers';

import {
    paymentsDuck,
    userDuck,
} from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

export default {

    compareListingsClick: (metadata = {}, option) => {
        const { data, state } = metadata;

        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

        return compareListingsClick({
            ...metadata,
            data: {
                ...data,
                pixallData: {
                    ...data.pixallData,
                    filterValues,
                },
            },
        }, option);
    },

    compareListingsModalPageView: (metadata, ...arg) => {
        const { data, state } = metadata;

        const { pixallData } = data;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const zipCode = _get(userDuck.selectors.getZip(state));

        return compareListingsModalPageView({
            ...metadata,
            data: {
                ...data,
                pixallData: {
                    ...pixallData,
                    filterValues,
                    zipCode,
                },
            },
        }, ...arg);
    },

    headerGeneralEventHandler: (metadata = {}, option) => {
        const { data, state } = metadata;

        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return headerGeneralEventHandler({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },

    offsiteBrowserPushNotificationClick: (metadata, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return offsiteBrowserPushNotificationClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                filterValues,
            },
            paymentsDuck,
        }, option);
    },

    offsiteBrowserPushNotificationImpression: (metadata, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return offsiteBrowserPushNotificationImpression({
            ...metadata,
            data: {
                ...data,
                filterValues,
                inventoryId: activeInventory.id,
            },
            paymentsDuck,
        }, option);
    },

    snap360Click: (metadata = {}, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return snap360Click({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },
};
